<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="!isNew && !category"
        cols="12"
      >
        <i class="fas fa-spin fa-spinner"></i>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <material-card
          color="primary"
          :heading="pageTitle"
        >
          <v-card-text>
            <i
              v-if="!isNew && !category"
              class="fas fa-spin fa-spinner"
            ></i>
            <validation-observer v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('forms.label').toLowerCase()"
                  >
                    <v-text-field
                      v-model="form.label"
                      :error-messages="errors"
                      outlined
                      :label="$t('forms.label')"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('forms.color').toLowerCase()"
                  >
                    <ColorPicker
                      v-model="form.color"
                      :error-messages="errors"
                      :label="$t('forms.color')"
                      class="mt-3"
                    />
                  </validation-provider>
                </div>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/news/categories"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import serverError from '../../mixins/serverError';
  export default {
    name: 'NewsCategoryForm',

    mixins: [serverError],

    data: () => ({
      form: {
        label: null,
        color: '#FFFFFF',
      },
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        category: 'news/getCategory',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewCategory') : this.$t('pageTitles.Editing') + this.category.label;
      },
    },

    created() {
      if (!this.isNew) {
        this.$store.dispatch('news/fetchCategory', this.$route.params.id)
          .then(() => {
            this.form = { ...this.category };
          });
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { id, label, color } = this.form
          const payload = { id, label, color };
          const action = this.isNew ? 'news/createCategory' : 'news/updateCategory';
          const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$router.push('/news/categories');
            })
            .catch(error => this.displayErrors(error));
        }
      },
    },
  }
</script>
